<template>
  <div>
    <div class="d-flex align-items-center mt-3">
      <!-- CHECK ALL BUTTON -->
      <b-form-checkbox
        class="custom-control-primary"
        @change="checkAll()"
        :checked="allItemsChecked"
      >
        Pilih Semua
      </b-form-checkbox>

      <!-- DELETE BUTTON -->
      <b-button variant="danger" class="ml-auto" @click="deleteChecked()">
        {{ button }}
      </b-button>
    </div>

    <!-- TRASH BIN ITEMS -->
    <div v-for="item in item_pages" :key="item.name" class="mt-2">
      <div class="mt-1 forAbsolute">
        <b-form-checkbox
          class="custom-control-primary"
          :checked="item.checked"
          @change="toggleSelect(item)"
        >
        </b-form-checkbox>
      </div>
      <newsMedsosCard />
    </div>

    <!-- PAGINATION -->
    <div class="row">
      <div class="col-md-12 float-right">
        <b-pagination
          v-model="current_page"
          :total-rows="items.length"
          :per-page="per_page"
          align="right"
          size="sm"
          @change="updatePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox, BButton, BPagination } from "bootstrap-vue";
import newsMedsosCard from "@/components/usable/newsMedsosCard.vue";

export default {
  name: "Trashbin",
  components: {
    BFormCheckbox,
    BButton,
    BPagination,
    newsMedsosCard,
  },

  data: () => ({
    title: "Trashbin | Online Media Monitoring",
    button: "Hapus Semua",
    current_page: 1,
    per_page: 5,
    item_pages: [],
    items: [
      {
        name: "1",
        checked: false,
      },
      {
        name: "2",
        checked: false,
      },
      {
        name: "3",
        checked: false,
      },
      {
        name: "4",
        checked: false,
      },
      {
        name: "5",
        checked: false,
      },
      {
        name: "6",
        checked: false,
      },
      {
        name: "7",
        checked: false,
      },
      {
        name: "8",
        checked: false,
      },
      {
        name: "9",
        checked: false,
      },
      {
        name: "10",
        checked: false,
      },
      {
        name: "11",
        checked: false,
      },
      {
        name: "12",
        checked: false,
      },
      {
        name: "13",
        checked: false,
      },
      {
        name: "14",
        checked: false,
      },
    ],
  }),

  created() {
    document.title = this.title;
  },

  mounted() {
    this.updateItemPages();
  },

  methods: {
    // CHECK ITEMS
    toggleSelect(item) {
      item.checked = !item.checked;
      this.button = "Hapus yang Ditandai";
    },
    // CHECK ALL ITEMS
    checkAll() {
      let futureCheckedValue = true;
      this.button = "Hapus Semua";

      if (this.allItemsChecked) {
        futureCheckedValue = false;
        this.button = "Hapus Semua";
      }

      this.items.forEach((item) => (item.checked = futureCheckedValue));
    },
    // DELETE ITEMS
    deleteChecked() {
      let varDelete = this.items.filter((el) => el.checked == true);
      console.log(varDelete);
    },
    // UPDATE PAGE
    updatePage(page_number) {
      this.current_page = page_number;
      this.updateItemPages();
    },
    // UPDATE ITEMS OF PAGE
    updateItemPages() {
      this.item_pages = this.items.slice(
        (this.current_page - 1) * this.per_page,
        (this.current_page - 1) * this.per_page + this.per_page
      );
      if (this.item_pages.length == 0 && this.current_pages == 0) {
        this.updatePage(this.current_pages - 1);
      }
    },
  },

  computed: {
    // ITEMS IS CHECKED
    checkedLength() {
      return this.items.filter((item) => item.checked).length;
    },
    // ALL ITEMS IS CHECKED
    allItemsChecked() {
      return this.checkedLength === this.items.length;
    },
  },
};
</script>
<style scoped>
.forAbsolute {
  position: absolute;
  z-index: 2;
  right: 0;
  margin-right: 40px;
  border-radius: 10px;
}
</style>